import { isNotBlank, slugify } from '@iheartradio/web.utilities';
import { isNonNullish } from 'remeda';
import { $path } from 'remix-routes';

/**
 * Generates a Playlist profile link
 * @param name The playlist name
 * @param slug (Optional) The playlist slug - this is the fallback if `name` is not provided
 * @param userId The user's profile Id
 * @param id The playlist Id
 * @returns The slugified playlist URL
 *
 * Example: `/playlist/curated-by-lauv-312064750-FSmzxQXiivrukGyQWzRd5G`
 */
export function buildPlaylistUrl({
  name,
  slug,
  userId,
  id,
}: {
  name: string;
  slug?: string;
  userId: number | string;
  id: string;
}) {
  const playlistSlug = `${slugify(name ?? slug)}-${userId}-${id}`;
  return $path('/playlist/:playlistSlug', { playlistSlug });
}

/**
 * Generates a Artist profile link
 * @param id The artist id
 * @param name The artist name
 * @returns The slugified artist URL
 *
 * Example: `/artist/title-fight-262247`
 */
export function buildArtistUrl({
  id,
  name,
}: {
  id: string | number;
  name: string;
}) {
  const artistSlug = `${slugify(name)}-${id}`;
  return $path(`/artist/:artistSlug`, { artistSlug });
}

/**
 * Generates a Album profile link
 * @param artist Object containing an `id` and `name`
 * @param artist.id The artist id
 * @param artist.name The artist name
 * @param album Object containing an `id` and `name`
 * @param album.id The album id
 * @param album.name The album name
 * @returns The slugified album URL
 *
 * Example: `/artist/title-fight-262247/album/floral-green-172498776`
 */
export function buildAlbumUrl({
  artist,
  album,
}: {
  artist: { id: string | number; name: string };
  album: { id: number | string; name: string };
}) {
  const { id: artistId, name: artistName } = artist;
  const artistSlug = `${slugify(artistName)}-${artistId}`;

  const { id: albumId, name: albumName } = album;
  const albumSlug = `${slugify(albumName)}-${albumId}`;

  return $path(`/artist/:artistSlug/album/:albumSlug`, {
    artistSlug,
    albumSlug,
  });
}

/**
 * Generates a Artist Albums profile link
 * @param id The artist id
 * @param name The artist name
 * @returns The slugified artist albums URL
 *
 * Example: `/artist/dwellings-31342830/albums`
 */
export function buildArtistAlbumsUrl({
  id,
  name,
}: {
  id: string | number;
  name: string;
}) {
  const artistSlug = `${slugify(name)}-${id}`;

  return $path('/artist/:artistSlug/albums', {
    artistSlug,
  });
}

/**
 * Generates a Song profile link
 * @param artist Object containing an `id` and `name`
 * @param artist.id The artist id
 * @param artist.name The artist name
 * @param track Object containing an `id` and `name`
 * @param track.id The track id
 * @param track.name The track name
 * @returns The slugified link
 *
 * Example: `/artist/bastille-662807/song/pompeii-23092888`
 */
export function buildSongUrl({
  artist,
  track,
}: {
  artist: { id: string | number; name: string };
  track: { id: number | string; name: string };
}) {
  const { id, name } = artist;
  const artistSlug = `${slugify(name)}-${id}`;

  const { id: trackId, name: trackName } = track;
  const trackSlug = `${slugify(trackName)}-${trackId}`;

  return $path(`/artist/:artistSlug/song/:trackSlug`, {
    artistSlug,
    trackSlug,
  });
}

/**
 * Generates a Artist Top Songs profile link
 * @param id The artist id
 * @param name The artist name
 * @returns The slugified artist top songs URL
 *
 * Example: `/artist/household-219147/songs`
 */
export function buildArtistTopSongsUrl({
  id,
  name,
}: {
  id: string | number;
  name: string;
}) {
  const artistSlug = `${slugify(name)}-${id}`;
  return $path('/artist/:artistSlug/songs', { artistSlug });
}

/**
 * Generates a Podcast profile link
 * @param podcastId The podcast Id
 * @param slug The podcast slug
 * @returns The slugified podcast URL
 *
 * Example: `/podcast/269-smartless-68519170`
 */
export function buildPodcastUrl({
  podcastId,
  slug,
}: {
  slug?: string;
  podcastId: string | number;
}) {
  const podcastSlug =
    isNotBlank(slug) && isNotBlank(podcastId) ? `${slugify(slug)}-${podcastId}`
    : isNotBlank(podcastId) ? `${podcastId}`
    : null;

  return podcastSlug ?
      $path('/podcast/:podcastSlug', { podcastSlug })
    : undefined;
}

/**
 * Generates a Podcast Episode profile link
 * @param podcastId The podcast Id
 * @param podcastName The podcast name
 * @param episodeId The podcast episode Id
 * @param episodeName The podcast episode name
 * @returns The slugified podcast episode URL
 *
 * Example: `/podcast/269-smartless-68519170/episode/david-beckham-177855388`
 */
export function buildPodcastEpisodeUrl({
  podcastId,
  podcastSlug,
  episodeId,
  episodeName,
}: {
  podcastId?: string | number;
  podcastSlug?: string;
  episodeId?: number | string;
  episodeName?: string;
}) {
  const podcastSlugAndId =
    isNotBlank(podcastSlug) && isNotBlank(podcastId) ?
      `${slugify(podcastSlug)}-${podcastId}`
    : isNotBlank(podcastId) ? `${podcastId}`
    : null;

  const episodeSlugAndId =
    isNotBlank(episodeId) && isNotBlank(episodeName) ?
      `${slugify(episodeName)}-${episodeId}`
    : isNotBlank(episodeId) ? `${episodeId}`
    : null;

  return podcastSlugAndId && episodeSlugAndId ?
      $path('/podcast/:podcastSlug/episode/:episodeSlug', {
        podcastSlug: podcastSlugAndId,
        episodeSlug: episodeSlugAndId,
      })
    : undefined;
}

/**
 * Generates a Live profile link
 * @param name The live station name
 * @param id The live station Id
 * @returns The slugified live station URL
 *
 * Example: `/live/wild-949-305`
 */
export function buildLiveUrl({
  name,
  id = '',
}: {
  name?: string;
  id?: number | string;
}) {
  const liveSlug = [
    name ? slugify(name.replace('.', '').toLowerCase()) : undefined,
    id,
  ]
    .filter(isNonNullish)
    .join('-');

  return $path('/live/:liveSlug', { liveSlug });
}

/**
 * Generates a Live Genre profile link
 * @param genreId Genre id needed to replace (id) in "/:genreId"
 * @returns the link
 */
export function buildLiveGenreUrl({ genreId }: { genreId: number | string }) {
  return $path('/browse/live/genre/:genreId', { genreId: String(genreId) });
}

/**
 * Generates a Artist Genre profile link
 * @param genreId Genre id needed to replace (id) in "/:genreId"
 * @returns the link
 */
export function buildArtistGenreUrl({ genreId }: { genreId: number | string }) {
  return $path('/browse/artists/genre/:genreId', { genreId: String(genreId) });
}

/**
 * Generates a Playlist Genre profile link
 * @param genreType Genre type needed to replace (id) in "/:genreType"
 * @param genreSlug Genre slug needed to replace (id) in "/:genreSlug"
 * @returns the link
 */
export function buildPlaylistGenreUrl({
  genreType,
  genreSlug,
}: {
  genreType: string;
  genreSlug: number | string;
}) {
  return $path('/browse/playlists/:genreType/:genreSlug', {
    genreType,
    genreSlug: String(genreSlug),
  });
}

/**
 * Generates a Podcast Topic link
 * @param topic Topic needed to replace (id) in "/:topic"
 * @param topicId Topic id  needed to replace (id) in "/:topicId"
 * @returns the link
 */
export function buildPodcastTopicUrl({
  topic,
  topicSlug,
}: {
  topic: string;
  topicSlug: string;
}) {
  return $path('/browse/podcasts/:topic/:topicSlug', {
    topic,
    topicSlug,
  });
}
